.page-services-fees {
  h1 {
    span {
      display: inline;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }
}