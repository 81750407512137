.component-news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.iframe {
  width: 100%;
  height: 400px;
  overflow: auto;
  margin-top: 1rem;
}