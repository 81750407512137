@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    src: url("./assets/fonts/avenir-book.ttf") format("truetype");
}

@font-face {
    font-family: 'Avenir';
    font-style: auto;
    font-weight: 700;
    src: url("./assets/fonts/avenir-heavy.ttf") format("truetype");
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

html {
    overflow-y: scroll;
}

body {
    line-height: 1.2;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    color: inherit;
    text-decoration: inherit;
}

* {
    font-family: 'Avenir', sans-serif !important;
}

div, a, span, p {
    color: #404040;
    font-size: 16px;
}

.content {
    width: calc(100% - 2rem);
    max-width: 1640px;
    padding: 0 1rem;
}

.highlight-color {
    color: #4766BA;
    transition: all .5s;
}

.secondary-color {
    color: #A5A5A5;
}

.bold {
    font-weight: 700;
}

h1, h2, h3 {
    font-weight: 700;
    text-transform: uppercase;

    span {
        margin-right: .5rem;
        display: inline-block;
    }
}

h1 {
    margin-bottom: 2rem;
}

h1,
h1 span {
    font-size: 3rem;
}

h2 {
    margin-bottom: 1rem;
}

h2,
h2 span {
    font-size: 2rem;
}

h3 {
    margin-bottom: .5rem;
}

h3,
h3 span {
    font-size: 1.2rem;

}

.paragraph-1 {
    font-size: 1.5rem;
}

.button {
    margin-bottom: 2rem;
    text-transform: uppercase;
    background: #4766BA;
    color: #fff;
    padding: 1rem 2rem;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    transition: all .2s;
    border: none;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);

    &:disabled{
        background: #ccc;
        cursor: not-allowed;
    }

    span {
        color: #fff;
    }

    &:hover:not(:disabled) {
        background: #478320;
    }

    &.negative:not(:disabled) {
        background: #d13530;
    }
}