.component-team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2rem;

  .doctors-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
    margin-top: 2rem;
  }

  .doctor {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 50%;
    max-width: 600px;

    @media (max-width: 900px) {
      flex: 100%;
    }

    .doctor-picture {
      flex: 1;
      margin-right: 1rem;
      width: 100%;
      height: 100%;
      min-width: 150px;
      min-height: 150px;
      max-width: 150px;
      max-height: 150px;
      border-radius: 50%;
      object-fit: cover;
      background: #fff;
    }

    .doctor-info {
      text-align: left;
      flex: 1;
      display: flex;
      flex-direction: column;

      .doctor-name {
        font-weight: bold;
        font-size: 1.2rem;
      }

      .doctor-title {
        font-style: italic;
        margin-bottom: 1rem;
      }

      .doctor-hours {
        font-style: italic;
        margin-bottom: 1rem;
        color: #6F707A;
      }

      .doctor-description {
        max-height: 100px;
        overflow: hidden;
        transition: max-height 2s ease-in-out;
        cursor: pointer;
        padding-bottom: 2rem;

        &:hover {
          max-height: 500px;
        }
      }

      .description-fade {
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
        width: 100%;
        height: 2rem;
        margin-top: -2rem;
      }
    }
  }
}