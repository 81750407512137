.component-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .content {
    margin: 1.25rem 0;
    display: flex;
    flex-direction: row;

    @media (max-width: 900px) {
      flex-direction: column;
    }
  }

  .menu-item-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
  }

  .menu-item {
    text-transform: uppercase;
    font-weight: 700;
    border-bottom: 4px solid #fff;
    transition: all .5s;

    a {
      font-size: 1.25rem;
    }

    &.active,
    &:hover {
      border-bottom: 4px solid #478320;
    }
  }
}