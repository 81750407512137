.component-table {
  th {
    font-weight: bold;
  }

  .table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .button {
      margin-left: 1rem;
    }
  }

  .button {
    margin: 0;
    padding: .5rem 1rem;
    font-size: 1rem;
  }
}