.component-waitlist-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  &.pdf-generated {
    min-width: 1640px;
    //padding: 2rem;

    label {
      background: #fff;
      padding: 0 10px;
      margin-top: -5px;
      margin-left: -5px;
    }
  }

  .content {
    margin-top: 1rem;
  }

  .form-section {
    padding: 1rem;
    background: #ADBBE0;
    color: #fff;
    font-size: 1.5rem;
    margin: 2rem 0;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0,0,0,.16);
  }

  .form-field {
    width: 100%;
  }

  .label {
    font-size: 1.25rem;
    margin-bottom: .25rem;
    display: block;
  }

  .subtext {
    font-size: .75rem;
    display: block;
  }

  .submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2rem;

    p {
      max-width: 500px;

      a {
        color: #4766BA;
        text-decoration: underline;
      }
    }
  }

  .field-section {
    margin-bottom: 2rem;
  }

  .field-name {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: .25rem;
    display: block;
  }

  .column {
    display: flex;
    flex-direction: column;

  }

  .row {
    display: flex;
    flex-direction: row;

    span {
      margin-right: .25rem;
    }
  }

  .entitlement-field {
    flex: 1;
    width: 100%;
    margin-bottom: 1rem;

    > div {
      display: flex;
      flex-direction: row;
    }

    p {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    .box {
      background: #e8e8e8;
      border: 1px solid #707070;
      padding: 1rem;
      min-width: 50px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }

    .id {
      margin-right: 1rem;
      text-align: center;
    }

    .checkbox svg {
      background: #fff;
    }

    .requirement {
      flex: 1;
      margin-right: 1rem;

      span:first-child {
        font-weight: bold;
      }

      span:last-child {
        font-style: italic;
        font-size: .8rem;
      }
    }
  }

  b {
    font-weight: bold;
  }
}