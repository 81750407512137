.page-home {
    .ad-banner {
        text-align: center;
        padding: 2rem;
        background: #4766BA;
        font-weight: bold;

        p {
            color: #fff;
        }

        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}
