.component-values {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;

  .content {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .value {
      flex: 1;
      display: flex;
      flex-direction: row;
      padding: 2rem;
      max-width: 400px;
    }
  }
}