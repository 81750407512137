.component-map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .content {
    position: absolute;
    width: 100%;
    height: 500px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .info {
    background: #4766BA;
    height: calc(500px - 4rem);
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }

  h2 {
    color: #fff;
    margin-top: 1rem;
  }

  .working-hours {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      border-bottom: 1px solid #fff;
    }

    span {
      flex: 1;
      padding: 1rem 0;
      font-size: 1rem;
      color: #fff;
    }
  }
}