.component-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 600px;

  @media (max-width: 900px) {
    height: 800px;
  }

  &.testimonials-container {
    height: 600px;

    @media (max-width: 900px) {
      height: 1600px;
    }
  }


  .content-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 2000px;
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 75%);

    &.mask-right {
      background: linear-gradient(90deg, rgba(255,255,255,0) 25%, rgba(255,255,255,1) 100%);
    }

    @media (max-width: 900px) {
      background: none !important;
    }
  }

  .hero {
    background-image: url('../../assets/images/hero.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
    height: calc(600px - 4rem);
    padding: 2rem 0;
    width: 100%;
    max-width: 2000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .2s;

    &.hero-2 {
      background-image: url('../../assets/images/hero-2.png');
    }

    &.hero-3 {
      background-image: url('../../assets/images/hero-3.png');
    }

    &.hero-4 {
      background-image: url('../../assets/images/hero-4.png');
    }

    &.hero-5 {
      background-image: url('../../assets/images/hero-5.png');
    }

    &.hero-6 {
      background-image: url('../../assets/images/hero-6.png');
    }

    &.hero-7 {
      background-image: url('../../assets/images/hero-7.png');
    }

    &.hero-8 {
      background-image: url('../../assets/images/hero-8.png');
    }

    @media (max-width: 900px) {
      height: calc(800px - 4rem);
      filter: blur(6px) brightness(1.1);
    }
  }

  .content {
    display: flex;
    flex-direction: row;
    position: absolute;
    padding: 2rem 0;
  }

  .grid-container {
    width: 100%;
  }

  .center {
    text-align: center;
  }

  .bottom-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-top: 1rem;

    .icon-circle {
      background: #4766BA;
      border-radius: 100%;
      min-width: 70px;
      width: 70px;
      height: 70px;
      margin-right: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .left-testimonial,
  .right-testimonial {
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .spacer {
      width: 200px;
      border-bottom: 3px solid #404040;
      margin-top: 1rem;
    }

    @media (max-width: 900px) {
      height: 800px;
    }

    .testimonials {
      margin-top: 2rem;
      display: flex;
      flex-direction: row;

      p {
        margin: 1rem;
      }

      @media (max-width: 900px) {
        flex-direction: column;
      }
    }
  }

  .right-testimonial {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
    background-image: url('../../assets/images/mask.png'), url('../../assets/images/kids-testimonial.png');

    .spacer {
      border-color: #fff;
    }

    span, p {
      color: #fff;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    padding: 1rem 2rem;
  }
}