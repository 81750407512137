.page-admin {
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
  }

  .field {
    margin-bottom: 1rem;
    width: 100%;
    max-width: 600px;
  }
}