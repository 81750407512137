.component-services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  th {
    font-weight: bold;

    .sublabel {
      display: block;
      font-weight: normal;
      font-style: italic;
      font-size: .7rem;
    }
  }
  
  .tooltip {
    font-weight: bold;
    cursor: pointer;
  }

  .price {
    display: flex;
    flex-direction: row;
    align-self: flex-start;

    .decimal {
      font-size: .7rem;
    }
  }

  .price-item {
    display: flex;
    flex-direction: row;

    .prefix,
    .suffix,
    .spacer {
      margin: 0 .25rem;
    }
  }

  p {
    margin: 1rem;
  }

  .table-section {
    padding: 1rem;
    background: #ADBBE0;
    color: #fff;
    font-size: 1rem;
  }

  .table-container {
    margin-bottom: 2rem;
  }
}