.component-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: .5rem 0;

  .column {
    display: flex;
    flex-direction: column;
  }

  span {
    margin-right: .25rem;
  }
}