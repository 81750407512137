.component-header {
  margin-top: 100px;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    height: 100px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  @media (max-width: 1200px) {
    .logo-cell {
      display: flex;
      justify-content: center;
    }
  }

  .logo {
    max-width: 300px;
    max-height: 63px;
  }
}