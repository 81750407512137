.component-footer {
  display: flex;
  flex-direction: column;

  form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .button {
    margin: 1rem;
    padding: .8rem 2rem;
  }

  .form-field {
    margin: 1rem 0;
    max-width: 300px;
    width: 100%;

    .MuiInputBase-root {
      background: #fff;
    }
  }

  h2 {
    margin-bottom: .5rem;
  }

  h3 {
    margin: 1rem 0;
  }

  .spacer {
    border-bottom: 2px solid #fff;
    width: 140px;
    margin-bottom: 1rem;
  }

  .top-section,
  .bottom-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    span, h2, h3, p, .link, a {
      color: #fff;
    }
  }

  .top-section {
    background: #6F707A;
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

  .bottom-section {
    background: #424242;
    padding: 1rem 0;
  }

  .link {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: .5rem;
  }

  nav {
    display: flex;
    flex-direction: column;
  }
}